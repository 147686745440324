import React from 'react';
import PropTypes from 'prop-types';

const ProductStatsCard = ({ data }) => {
  return (
    <div className="product-stats-card">
      <div className="icon">{data.icon}</div>
      <div className="stats">{data.stats}</div>
      <div className="title">{data.title}</div>
    </div>
  );
};

ProductStatsCard.propTypes = {
  data: PropTypes.object,
};

ProductStatsCard.defaultProps = {};

export default ProductStatsCard;
